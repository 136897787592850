import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { IconLogo } from 'app/components/Icons'
import disableScroll from 'disable-scroll'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

export interface Props {
  images: ImageProps[]
  languageCode: string
  thumbnails?: ImageProps[]
  title?: string
}

export const GalleryGrid = memo(function GalleryGrid({
  images,
  languageCode,
  thumbnails,
  title,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            disableScroll.off()
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <IconLogo />

      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}

      {thumbnails ? (
        <Thumbs
          options={{
            gutter:
              typeof window !== 'undefined'
                ? window.innerWidth > 1199
                  ? 30
                  : 20
                : 30,
            percentPosition: true,
            transitionDuration: 0,
          }}
        >
          {uniq(thumbnails).map((item, index) => (
            <Thumb
              key={index}
              onClick={() => {
                if (!modalGalleryStatus) {
                  setModalGalleryStatus(true)
                  disableScroll.on()
                } else {
                  setModalGalleryStatus(false)
                  disableScroll.off()
                }

                setClickedSlide(index)
                setTimeout(() => {
                  setClickedSlide(null)
                }, 100)
              }}
            >
              <LazyLoadComponent>
                <Image {...item} />
              </LazyLoadComponent>
            </Thumb>
          ))}
        </Thumbs>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              disableScroll.off()
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
            variant="dark"
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  padding: 6.25rem 10.556vw 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 34.75vh);
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1339px) {
    &:before {
      height: calc(100% - 20vh);
    }
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
    padding: 4.375rem 1.875rem 0;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem 0 7.5rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    &:before {
      height: calc(100% - 3.125rem);
    }
  }
`

const Title = styled.h2`
  max-width: 55rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: auto;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Thumbs = styled(Masonry)`
  margin: 4.125rem -1.875rem 0 0;
  overflow: hidden;

  @media (max-width: 1199px) {
    margin-right: -1.5rem;
  }

  @media (max-width: 767px) {
    margin-right: -1.25rem;
  }
`

const Thumb = styled.div`
  width: calc(25% - 1.875rem);
  float: left;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  overflow: hidden;
  margin-top: 1.875rem;
  padding-bottom: 17%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &:nth-of-type(2),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    padding-bottom: 30%;
  }
  &:nth-of-type(3),
  &:nth-of-type(8) {
    padding-bottom: 23%;
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(25% - 1.5rem);
    margin-top: 1.5rem;
  }

  @media (max-width: 767px) {
    width: calc(50% - 1.25rem) !important;
    margin-top: 1.25rem;
    padding-bottom: calc(50% - 1.25rem) !important;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  @media (max-width: 1199px) {
    > div {
      &:first-of-type {
        top: 1.625rem;
        right: 1.875rem;
      }
    }
  }
`
