import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Info = memo(function Info({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      <Brand>
        <IconLogo />
      </Brand>

      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}
      {list ? (
        <FadeInUp>
          <List>
            {list.map((item: any, index) => (
              <Item
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            ))}
          </List>
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 58.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 3.75rem;
  margin: 14.375rem auto 12.6875rem;
  padding: 0 10.556vw 6.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 9.375rem auto 7.5rem;
    padding: 0 1.875rem 4.375rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    margin: 7.5rem 1.875rem;
  }

  @media (max-width: 767px) {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Brand = styled.div`
  width: 10.25rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 0 auto 0.625rem;
  transform: translateY(-1.875rem);

  svg {
    width: auto;
    height: 4.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    transform: translateY(-0.5625rem);
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;
  margin-bottom: 2.5rem;

  > div {
    margin-right: auto;
    margin-left: auto;
  }
`

const List = styled.div``

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  &:first-of-type {
    &:before {
      display: none;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    border-radius: 50%;
    margin: 1.875rem auto;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    &:before {
      width: 0.5rem;
      height: 0.5rem;
      margin: 1.5rem auto;
    }
  }
`
