import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { GalleryGrid } from 'app/components/GalleryGrid'
import { Hero } from 'app/components/Hero'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZag } from 'app/components/ZigZag'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function BeachPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="simple" {...context.introProps} />
      ) : null}
      {context.zigZagProps ? <ZigZag {...context.zigZagProps} /> : null}
      {context.galleryGridProps ? (
        <GalleryGrid {...context.galleryGridProps} />
      ) : null}
      {context.infoProps ? <Info {...context.infoProps} /> : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
